// 🛑 NOTICE: __generated__ folders should be added to .gitignore
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import { RawFlightFragment } from './RawFlightFragment.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllRawFlightsVariables = Types.Exact<{
  req?: Types.InputMaybe<Types.GetAllRawFlightsRequest_Input>;
}>;


export type GetAllRawFlights = { __typename: 'Query', flights_getAllRaw?: { __typename: 'GetAllRawFlightsResponse', flights: Array<{ __typename: 'RawFlight', id: string, arrivalTime: string, carrierId: string, departureTime: string, driver1?: string | null, driver2?: string | null, driver3?: string | null, isHidden: boolean, name: string, nid: string, rideNumber?: number | null, rideType: Types.EFlightType, routeId: string, status: Types.EFlightStatus, tenantId: string, vehicleId: string } | null> } | null };


export const GetAllRawFlightsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAllRawFlights"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"req"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GetAllRawFlightsRequest_Input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flights_getAllRaw"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"req"},"value":{"kind":"Variable","name":{"kind":"Name","value":"req"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flights"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"RawFlightFragment"}}]}}]}}]}},...RawFlightFragment.definitions]} as unknown as DocumentNode;

/**
 * __useGetAllRawFlights__
 *
 * To run a query within a React component, call `useGetAllRawFlights` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRawFlights` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRawFlights({
 *   variables: {
 *      req: // value for 'req'
 *   },
 * });
 */
export function useGetAllRawFlights(baseOptions?: Apollo.QueryHookOptions<GetAllRawFlights, GetAllRawFlightsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRawFlights, GetAllRawFlightsVariables>(GetAllRawFlightsDocument, options);
      }
export function useGetAllRawFlightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRawFlights, GetAllRawFlightsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRawFlights, GetAllRawFlightsVariables>(GetAllRawFlightsDocument, options);
        }
export function useGetAllRawFlightsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllRawFlights, GetAllRawFlightsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRawFlights, GetAllRawFlightsVariables>(GetAllRawFlightsDocument, options);
        }
export type GetAllRawFlightsHookResult = ReturnType<typeof useGetAllRawFlights>;
export type GetAllRawFlightsLazyQueryHookResult = ReturnType<typeof useGetAllRawFlightsLazyQuery>;
export type GetAllRawFlightsSuspenseQueryHookResult = ReturnType<typeof useGetAllRawFlightsSuspenseQuery>;
export type GetAllRawFlightsQueryResult = Apollo.QueryResult<GetAllRawFlights, GetAllRawFlightsVariables>;